# 2 "libs/engine/lexer.mll"
 
  open Utils
  open Parser

  module L = Location

  (* ------------------------------------------------------------------ *)
  let lex_error lexbuf msg =
    raise (Syntax.ParseError (Some (L.of_lexbuf lexbuf), Some msg))

  (* ------------------------------------------------------------------ *)
  let _keywords = [
    "true"  , TRUE  ;
    "exists", EXISTS;
    "false" , FALSE ;
    "forall", FORALL;
    "rec"   , REC   ;
    "type"  , TYPE  ;
  ]

  (* ------------------------------------------------------------------ *)
  let keywords =
    let table = Hashtbl.create 0 in
    List.iter (curry (Hashtbl.add table)) _keywords; table

# 28 "libs/engine/lexer.ml"
let __ocaml_lex_tables = {
  Lexing.lex_base =
   "\000\000\230\255\231\255\232\255\233\255\236\255\033\000\239\255\
    \240\255\241\255\002\000\000\000\245\255\018\000\001\000\248\255\
    \249\255\250\255\251\255\079\000\098\000\002\000\255\255\247\255\
    \242\255\246\255\244\255\002\000\243\255\235\255\237\255";
  Lexing.lex_backtrk =
   "\255\255\255\255\255\255\255\255\255\255\255\255\017\000\255\255\
    \255\255\255\255\025\000\025\000\255\255\025\000\021\000\255\255\
    \255\255\255\255\255\255\003\000\002\000\001\000\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255";
  Lexing.lex_default =
   "\001\000\000\000\000\000\000\000\000\000\000\000\255\255\000\000\
    \000\000\000\000\255\255\255\255\000\000\255\255\255\255\000\000\
    \000\000\000\000\000\000\255\255\255\255\255\255\000\000\000\000\
    \000\000\000\000\000\000\255\255\000\000\000\000\000\000";
  Lexing.lex_trans =
   "\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\021\000\022\000\021\000\000\000\021\000\000\000\021\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \021\000\000\000\021\000\000\000\000\000\000\000\014\000\023\000\
    \018\000\017\000\003\000\004\000\009\000\011\000\008\000\027\000\
    \019\000\019\000\019\000\019\000\019\000\019\000\019\000\019\000\
    \019\000\019\000\006\000\007\000\010\000\005\000\026\000\024\000\
    \028\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\030\000\000\000\000\000\029\000\000\000\
    \000\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\016\000\013\000\015\000\012\000\019\000\
    \019\000\019\000\019\000\019\000\019\000\019\000\019\000\019\000\
    \019\000\020\000\000\000\000\000\000\000\000\000\025\000\000\000\
    \000\000\000\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\000\000\000\000\000\000\
    \000\000\020\000\000\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \002\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000";
  Lexing.lex_check =
   "\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\000\000\000\000\021\000\255\255\000\000\255\255\021\000\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \000\000\255\255\021\000\255\255\255\255\255\255\000\000\014\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\010\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\011\000\013\000\
    \027\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\006\000\255\255\255\255\006\000\255\255\
    \255\255\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\000\
    \000\000\000\000\000\000\000\000\000\000\000\000\000\000\019\000\
    \019\000\019\000\019\000\019\000\019\000\019\000\019\000\019\000\
    \019\000\020\000\255\255\255\255\255\255\255\255\013\000\255\255\
    \255\255\255\255\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\255\255\255\255\255\255\
    \255\255\020\000\255\255\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\020\000\020\000\020\000\
    \020\000\020\000\020\000\020\000\020\000\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \000\000\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\255\
    \255\255\255\255\255\255";
  Lexing.lex_base_code =
   "";
  Lexing.lex_backtrk_code =
   "";
  Lexing.lex_default_code =
   "";
  Lexing.lex_trans_code =
   "";
  Lexing.lex_check_code =
   "";
  Lexing.lex_code =
   "";
}

let rec main lexbuf =
   __ocaml_lex_main_rec lexbuf 0
and __ocaml_lex_main_rec lexbuf __ocaml_lex_state =
  match Lexing.engine __ocaml_lex_tables __ocaml_lex_state lexbuf with
      | 0 ->
# 43 "libs/engine/lexer.mll"
                ( Lexing.new_line lexbuf; main lexbuf )
# 158 "libs/engine/lexer.ml"

  | 1 ->
# 44 "libs/engine/lexer.mll"
                ( main lexbuf )
# 163 "libs/engine/lexer.ml"

  | 2 ->
let
# 45 "libs/engine/lexer.mll"
             id
# 169 "libs/engine/lexer.ml"
= Lexing.sub_lexeme lexbuf lexbuf.Lexing.lex_start_pos lexbuf.Lexing.lex_curr_pos in
# 45 "libs/engine/lexer.mll"
                ( try Hashtbl.find keywords id with Not_found -> IDENT id )
# 173 "libs/engine/lexer.ml"

  | 3 ->
let
# 46 "libs/engine/lexer.mll"
           n
# 179 "libs/engine/lexer.ml"
= Lexing.sub_lexeme lexbuf lexbuf.Lexing.lex_start_pos lexbuf.Lexing.lex_curr_pos in
# 46 "libs/engine/lexer.mll"
                ( NAT (int_of_string n) )
# 183 "libs/engine/lexer.ml"

  | 4 ->
# 48 "libs/engine/lexer.mll"
          ( LPAREN    )
# 188 "libs/engine/lexer.ml"

  | 5 ->
# 49 "libs/engine/lexer.mll"
          ( RPAREN    )
# 193 "libs/engine/lexer.ml"

  | 6 ->
# 50 "libs/engine/lexer.mll"
          ( LBRACE    )
# 198 "libs/engine/lexer.ml"

  | 7 ->
# 51 "libs/engine/lexer.mll"
          ( RBRACE    )
# 203 "libs/engine/lexer.ml"

  | 8 ->
# 52 "libs/engine/lexer.mll"
          ( LAND      )
# 208 "libs/engine/lexer.ml"

  | 9 ->
# 53 "libs/engine/lexer.mll"
          ( LOR       )
# 213 "libs/engine/lexer.ml"

  | 10 ->
# 54 "libs/engine/lexer.mll"
          ( LNEG      )
# 218 "libs/engine/lexer.ml"

  | 11 ->
# 55 "libs/engine/lexer.mll"
          ( LARROW    )
# 223 "libs/engine/lexer.ml"

  | 12 ->
# 56 "libs/engine/lexer.mll"
          ( LRARROW   )
# 228 "libs/engine/lexer.ml"

  | 13 ->
# 57 "libs/engine/lexer.mll"
          ( PROOF     )
# 233 "libs/engine/lexer.ml"

  | 14 ->
# 58 "libs/engine/lexer.mll"
          ( COMMA     )
# 238 "libs/engine/lexer.ml"

  | 15 ->
# 59 "libs/engine/lexer.mll"
          ( DOT       )
# 243 "libs/engine/lexer.ml"

  | 16 ->
# 60 "libs/engine/lexer.mll"
          ( SEMICOLON )
# 248 "libs/engine/lexer.ml"

  | 17 ->
# 61 "libs/engine/lexer.mll"
          ( COLON     )
# 253 "libs/engine/lexer.ml"

  | 18 ->
# 62 "libs/engine/lexer.mll"
          ( DCOLON    )
# 258 "libs/engine/lexer.ml"

  | 19 ->
# 63 "libs/engine/lexer.mll"
          ( EQ        )
# 263 "libs/engine/lexer.ml"

  | 20 ->
# 64 "libs/engine/lexer.mll"
          ( COLONEQ   )
# 268 "libs/engine/lexer.ml"

  | 21 ->
# 65 "libs/engine/lexer.mll"
          ( AMP       )
# 273 "libs/engine/lexer.ml"

  | 22 ->
# 66 "libs/engine/lexer.mll"
          ( PLUS      )
# 278 "libs/engine/lexer.ml"

  | 23 ->
# 67 "libs/engine/lexer.mll"
          ( STAR      )
# 283 "libs/engine/lexer.ml"

  | 24 ->
# 69 "libs/engine/lexer.mll"
        ( EOF )
# 288 "libs/engine/lexer.ml"

  | 25 ->
let
# 71 "libs/engine/lexer.mll"
          c
# 294 "libs/engine/lexer.ml"
= Lexing.sub_lexeme_char lexbuf lexbuf.Lexing.lex_start_pos in
# 71 "libs/engine/lexer.mll"
            ( lex_error lexbuf (Printf.sprintf "illegal character: %c" c) )
# 298 "libs/engine/lexer.ml"

  | __ocaml_lex_state -> lexbuf.Lexing.refill_buff lexbuf;
      __ocaml_lex_main_rec lexbuf __ocaml_lex_state

;;

